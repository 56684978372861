/*global $*/
/*eslint no-undef: "error"*/
export default class ButtonOrder {
    classButton = 'button-order--button'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            window.interSpecServis.FormPopupOrder.fields.id = $(this).data('product-id')
            window.interSpecServis.FormPopupOrder.fields.pageName = $(this).data('product')
        })
    }
}
