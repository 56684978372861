/*global $*/
/*eslint no-undef: "error"*/

import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs, Lazy} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs, Lazy])

export default class SwiperSlider {
    createSliderReviewClient () {
        return new Swiper('#slider--review-client', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-client-next',
                prevEl: '.slider--review-client-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1240: {
                    slidesPerView: 3
                }
            }
        })
    }

    createSliderProductsMore () {
        return new Swiper('#slider--products-more', {
            slidesPerView: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--review-client-next',
                prevEl: '.slider--review-client-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 3
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 4
                }
            }
        })
    }

    createSliderHall () {
        return new Swiper('.slider--hall', {
            slidesPerView: 1,
            spaceBetween: 5,
            loop: true,
            navigation: {
                nextEl: '.slider--hall-next',
                prevEl: '.slider--hall-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            breakpoints: {}
        })
    }

    constructor () {
        if(document.getElementById('slider--review-client')!= null) {
            this.createSliderReviewClient()
        }

        if(document.getElementById('slider--products-more')!= null) {
            this.createSliderProductsMore()
        }

        if (parseInt($('.slider--hall').length) > 0) {
            this.createSliderHall()
        }
    }
}
